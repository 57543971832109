$primary:#E77AA5;
$secondary:#FFE7F0;
$tertiary:#a1a1a1;
$darkpink:#ff3d8b;
$primary-font:'Montserrat', sans-serif;
$secondary-font:'Roboto', sans-serif;

h1,h2,h3,h5{
  font-family: $primary-font;
}
span,p{
  font-family: $secondary-font;
}

.display-mobile{
  display: none;
}

.link-underline{
  color: black;
}
.link-underline:hover{
  color: black;
  text-decoration: underline;
}

.dashboard-container{
  height: 100vh;
  overflow-y: scroll;
}
::-webkit-scrollbar{
  width:5px !important;
}

::-webkit-scrollbar-track{
  background:transparent !important;
}


::-webkit-scrollbar-thumb{
  background:rgb(235, 235, 235) !important;
  opacity:0.2;
  border-radius:25px
}

::-webkit-scrollbar-thumb:hover{
  background:#5a1e96 !important;
}


@media (max-width: 992px) {

}
@media (max-width: 768px) {


}
@media (max-width: 576px) {
  .alert{
    position: absolute;
    bottom: 0;
    z-index: 5;
    left: 0%;
  }
  .display-mobile{
    display: block;
  }

}