@import  "../../App.scss";
.company-info-section{
    background-color: white;
    margin: 2% 3% !important;
    border-radius: 10px;
    padding: 2%;
    h2{
        font-weight: 500;
        font-size: 1.5em;
    }
    .key-holders{
        font-weight: bold;
    }
    img{
        width: 100%;
        border-radius: 100%;
    }
}

.light-text{
    font-weight: 500;
}