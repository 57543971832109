@import  "../../App.scss";

.border-100{
    border-radius: 100%;
}

.logo50{
 width: 100%;   
}
.modal-textfield{
    margin: 5% auto !important;
    display: block;
}
.create-job{
    padding: 2%;
    .job-form{
        h2{
            font-weight: 700;
        }
        .sub-heading{
            font-weight: 400;
            font-size: 2em;
        }
        .note-text{
            font-weight: bold;
            color: #a1a1a1;
        }
        .slider-info{
            color: $primary;
            font-weight: bold;
        }
    }
    .sample-form{
        .sample-form-main-heading{
            font-weight: 300;
            font-size: 2.5em;
            text-align: center;
        }
    }
}
.singlejob-first-row{
    .job-content{
        img{
            width: 100%;
            border-radius: 100%;
        }
    }
}

.recruiter-content{
    background-color: white;
    padding: 2%;
    height: 100%;
    border-radius: 10px;
    img{
        width: 100%;
        border-radius: 50%;
    }
}

.can-parent{
    .can-child{
        margin: 3% 0 !important;
        padding: 2% 0;
        border-radius: 10px;
        img{
            width: 100%;
            border-radius: 100%;
        }
    }
}

.rejected{
    background-color: rgba(255, 0, 0, 0.082);
}

.hired{
    background-color: rgba(0, 255, 21, 0.082);
}