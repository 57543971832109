@import  "../../App.scss";

.auth-form{
    margin: auto;
    width: 40%;
    text-align: center;
    h1{
        font-weight: 600;
    }
    img{
        width: 50%;
        display: block;
        margin: auto;
    }
}