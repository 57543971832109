@import  "../../App.scss";


.create-form{
    padding: 2% 5% !important;
}

.candidate-info-section{
    background-color: white;
    margin: 2% !important;
    padding: 2%;
    border-radius: 10px;
    h1{
        font-weight: 500;
    }
    .bold-text{
        font-weight: bold;
    }
    .img-cont{
        text-align: center;
        img{
            width: 100%;
            border-radius: 100%;
        }
    }

    

    .resume-div{
        .resume-head{
            background-color: #f7f7f7;
            padding: 4%;
            border: 1px solid #e6e6e6;
            .resume-child{
                background-color: white;
                border: 1px solid #e6e6e6;
                padding: 2%;
                h4{
                    margin: auto;
                }
                p{
                    margin-top: 5px;
                    font-size: .8em;
                }
            }
            .resume-child:hover{
                cursor: pointer;
                text-decoration: underline;
            }
        }

        .subs-div{
            background-color: #f7f7f7;
            margin: 4% 0 !important;
            padding: 4% 2%;
            border-radius: 10px;
            border: 1px solid #e6e6e6;
        }
    }

}


.view-section-head{
    .view-section-child{
        background-color: white;
        padding: 1%;
        border-radius: 10px;
        font-weight: bold;
        
    }
    p{
        font-size: 1.2em;
    }
    h2{
        font-size: 2.5em;
        color: $primary;
    }
}

.info-sections{
    
    .info-child{
        border-radius: 10px;
        margin: 2% 0 !important;
        padding: 2%;
        background-color: white;
        h2{
            font-size: 1.5em;
            font-weight: 500;
        }
    }

    .info-sub-content{
        border-bottom: 1px solid #e9e9e9;
        padding-bottom: 2%;
        margin-top: 3% !important;
        .key{
            margin-bottom: 0;
            font-size: 1.2em;
            font-weight: 500;
        }
        .val{
            color: #a1a1a1;
            margin: 0;
        }
        .datefromto{
            margin: 0;
            font-size: .8em;
            color: #a1a1a1;
        }
    }
}

.job-apply-head{
    padding: 2%;
    margin-bottom: 2% !important;
    background-color: white;
    img{
        width: 100%;
    }
    .companyName{
        color: #a1a1a1;
        margin: .5% 0 !important;
    }
    .total-reviews{
        color: #a1a1a1;
    }
    .key-headline{
        font-weight: 500;
    }
    
}
.Hired{
    background-color: rgb(241, 255, 241) !important;
    .status{
        background-color: rgba(132, 214, 132, 0.5) !important;
        text-align: center;
        color: rgb(2, 148, 2);
        font-weight: 500;
        font-size: 1.2em;
        border-radius: 5px;
        padding: 3%;
    }
}

.Pending{
    background-color: rgb(255, 255, 255) !important;
    .status{
        background-color: rgba(170, 170, 170, 0.5) !important;
        text-align: center;
        color: rgb(63, 63, 63);
        font-weight: 500;
        font-size: 1.2em;
        border-radius: 5px;
        padding: 3%;
    }
}

.Rejected{
    background-color: rgb(255, 242, 242) !important;
    .status{
        background-color: rgba(255, 124, 124, 0.5) !important;
        text-align: center;
        color: rgb(148, 0, 0);
        font-weight: 500;
        font-size: 1.2em;
        border-radius: 5px;
        padding: 3%;
    }
}

.hired-status{
    background-color: rgba(132, 214, 132, 0.5) !important;
    text-align: center;
    color: rgb(2, 148, 2);
    font-weight: 500;
    font-size: 1.2em;
    border-radius: 5px;
    padding: 3%;
}

.rejected-status{
    background-color: rgba(255, 124, 124, 0.5) !important;
    text-align: center;
    color: rgb(148, 0, 0);
    font-weight: 500;
    font-size: 1.2em;
    border-radius: 5px;
    padding: 3%;
}

